require('jquery-ui/ui/core.js');
require('jquery-ui/ui/widgets/sortable.js');

$(function() {
  $('table.fw-formbuilder-fields').find('tbody:not(.static)').sortable({
    handle: '.drag-handle',
    axis: 'y',
    stop: function (event, ui) {
      var data = $(event.target).sortable('toArray', {attribute: "data-field-name"});
      var url = $('table.fw-formbuilder-fields').data('update-order-url');

      $.post(url, { order: data });
    }
  });

  $('table.fw-sortable').find('tbody:not(.static)').sortable({
    handle: '.drag-handle',
    axis: 'y',
    stop: function (event, ui) {
      var data = $(event.target).sortable('toArray', {attribute: "data-id"});
      var url = $('table.fw-sortable').data('update-order-url');

      $.post(url, { order: data });
    }
  });
});