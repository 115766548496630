require('../../scss/backend/backend.scss');

// --- Checked for new Symfony 5 structure:
require('admin-theme-bundle/assets/js/theme');

require('./flashes.js');

// Unchecked
var $ = require('jquery');
this.$ = window.$ = $;

if (document.querySelectorAll('[data-fw-at-search-bar]').length > 0) {
    import(/* webpackChunkName: "fw-at-search-bar" */ 'admin-theme-bundle/assets/js/modules/search-bar')
}


require('./formbuilder.js');
require('./orderbuilder.js');
require('./bootstrap-pdf-viewer');
require('./user-admin');
require('./dashboards');
require('./auto-update-table');
require('./colorpicker');
require('./table-selectable');
require('./sticky-table-header');

if (document.getElementById('tc-webshop-items-app')) {
    require('./webshop-item-collection');
}

if (document.querySelectorAll('[data-fw-at-croppable-image]').length > 0) {
    import(/* webpackChunkName: "fw-at-croppable-image" */ 'admin-theme-bundle/assets/js/modules/croppable-image')
}

if (document.querySelectorAll('[data-blockeditor]')) {
    require('./blockEditor/index');
}

$(function () {

    // Just a little fun on the console.
    console.log('%cTicketCrew %cBackstage\r\n'+
        '%cOntwikkeld door: %cEncore Digital Agency%c - %chttps://encore.nl/',
        'color: #00a1bd; font-weight: bold; font-size: 1.3em;',
        'color: #666; font-size: 1.3em;',
        'color: #888',
        'color: #880DD5; font-weight: bold',
        'color: #888',
        'color: #00f; text-decoration: underline;'
    )
    ;

    // -- Bootstrap tab handling
    // Auto switch to tab based on URL fragment
    var url = document.location.toString();
    if (url.match('#')) {
        //$('a[data-bs-toggle=tab][data-bs-target="#' + url.split('#')[1] + '"]').tab('show');
    }


    // Dropdown table (Crew reports)
    $('.js-table-dropdown .table-dropdown-toggle').each(function () {
        var $dropdownRows = $(this).closest('table').find('tr[data-dropdown-identifier="' + $(this).data('dropdown-identifier') + '"]')
        if ($(this).hasClass('dropdown-closed')) {
            $dropdownRows.hide()
        }
    })
    $('.js-table-dropdown .table-dropdown-toggle').on('click', function () {
        var $dropdownRows = $(this).closest('table').find('tr[data-dropdown-identifier="' + $(this).data('dropdown-identifier') + '"]')
        if ($(this).hasClass('dropdown-open')) {
            $dropdownRows.hide()
            $dropdownRows.find('.table-dropdown-toggle.dropdown-open').trigger('click')
        } else {
            $dropdownRows.show()
        }
        $(this).toggleClass('dropdown-open');
        $(this).toggleClass('dropdown-closed');
    })

    // Toggle(/expand) button
    $('.js-toggle').on('click', function (e) {
        var $btn = $(this);
        var $target = $('#' + $btn.data('target'));
        $target.toggleClass('hidden');
        e.preventDefault();
    })

    // Tiny MCE (Rich Text Editor)
    if ($('.tinymce').length > 0) {
        tinymce.init({
            selector: '.tinymce',
            theme: 'modern',
            menubar: false,
            statusbar: false,
            language: 'nl',
            height: 300,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste code'
            ],
            toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code'
        });
    }

    if ($('.tinymce-mail').length > 0) {
        tinymce.init({
            selector: '.tinymce-mail',
            statusbar: false,
            menubar: false,
            language: 'nl',
            height: 500,
            plugins: 'link',
            toolbar: 'bold italic | link',
        });
    }

    $('.td-scan-button').each(function () {
        // get reference to button
        var $btn = $(this);
        // update reference to icon
        $btn.data('icon', $btn.find('i'));

        // handle click
        $btn.on('click', function (e) {

            // prevent browser handling/form posting
            e.preventDefault();

            // mark as loading
            $btn.removeClass('btn-primary').removeClass('btn-success').removeClass('btn-danger').addClass('btn-info');
            $btn.data('icon').attr('class', 'fal fa-circle-o-notch fa-spin fa-fw');

            var url = $btn.data('href');
            $.post({

                url: url

            }).then(function (data) {

                // update button state
                $btn.removeClass('btn-info').addClass('btn-success').addClass('disabled');
                $btn.data('icon').attr('class', 'fa fa-check fa-fw');

                // mUpdated all badges on the page
                var barcode = $btn.data('barcode');
                $('.td-ticket-status-badge.bg-success[data-barcode=' + barcode + ']')
                    .removeClass('bg-success')
                    .addClass('bg-warning')
                    .html('<span class="ks-icon fa fa-check"></span> Gescand');

            }).fail(function () {

                $btn.removeClass('btn-info').addClass('btn-danger');
                $btn.data('icon').attr('class', 'fa fa-times fa-fw');

            })
        })

        // Automatically click the button if auto-scan is enabled.
        if ($btn.data('auto-scan')) {
            $btn.trigger('click');
        }
    })

    $('.td-print-button').each(function () {
        // get reference to button
        var $btn = $(this);
        // update reference to icon
        $btn.data('icon', $btn.find('i'));

        // handle click
        $btn.on('click', function (e) {

            // prevent browser handling/form posting
            e.preventDefault();

            // mark as loading
            $btn.removeClass('btn-warning').removeClass('btn-success').removeClass('btn-danger').addClass('btn-info');
            $btn.data('icon').attr('class', 'fal fa-circle-o-notch fa-spin fa-fw');

            var url = $btn.data('href');
            $.post({

                url: url

            }).then(function () {

                // update button state
                $btn.removeClass('btn-info').addClass('btn-success').addClass('disabled');
                $btn.data('icon').attr('class', 'fa fa-check fa-fw');

            }).fail(function () {

                $btn.removeClass('btn-info').addClass('btn-danger');
                $btn.data('icon').attr('class', 'fa fa-times fa-fw');

            })
        })

        // Automatically click the button if auto-print is enabled.
        if ($btn.data('auto-print')) {
            $btn.trigger('click');
        }
    })

    // On the scanner setup page, we want to automatically return when a code has been scanned
    // This script checks if the auth code has been used and then returns
    if ($('.js-scanner-autoreturn').length) {

        var url = $('.js-scanner-autoreturn').data('check-url');

        window.setInterval(function () {
            $.get(url).then(function (response) {
                if (!response.exists) {
                    window.location = $('.js-scanner-autoreturn').attr('href');
                }
            });
        }, 3000);
    }

    // Checkbox button in the header
    if ($('table thead .js-toggle-all').length > 0) {
        // set initial state
        function updateToggleState($toggle) {
            var $checkboxes = $toggle.closest('table').find('tbody input[type=checkbox]');
            var $checkedCheckboxes = $toggle.closest('table').find('tbody input[type=checkbox]:checked');
            $toggle.prop('checked', $checkboxes.length === $checkedCheckboxes.length);
        }

        $('table thead .js-toggle-all').each(function () {
            var $toggle = $(this);
            updateToggleState($toggle);
        })

        $('table thead .js-toggle-all').on('change', function () {
            var $toggle = $(this);
            var value = $toggle.is(':checked');

            var $checkboxes = $toggle.closest('table').find('tbody input[type=checkbox]');
            $checkboxes.prop('checked', value);
        })
    }

    $('.tc-multi-select').each(function () {
        var $select = $(this);
        $select.select2MultiCheckboxes({
            width: "auto",
            templateSelection: function (selected, total) {
                if (selected.length === 0) {
                    return $select.attr('placeholder');
                }
                return selected.length + ' geselecteerd';
            }
        })
    })

    $('input[type=url]').on('blur', function () {
        var value = $(this).val();
        if (value && !~value.indexOf("http")) {
            value = "http://" + value;
            $(this).val(value);
        }
    })

    $('.js-acc-remarks').on('keypress', function (event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            var $input = $(this);
            $.post($input.data('href'), {remarks: $input.val()});
        }
        return event.keyCode !== 13;
    })

    // Placerings blok op pagina nieuw evenement
    if ($('[data-tc-new-event-with-seating]').length > 0) {

        var $seatplanList = $('#event_seatplan');
        var $seatplanCapacityList = $('#event_seatplanCapacity');

        var $input = $('<select />')
            .attr('name', $seatplanCapacityList.attr('name'))
            .attr('class', $seatplanCapacityList.attr('class'))
            .attr('id', $seatplanCapacityList.attr('id'))
        ;
        $seatplanCapacityList.replaceWith($input);
        $seatplanCapacityList = $input;

        var capacityListRequest = null

        $seatplanList.on('change', function () {

            // if (capacityListRequest !== null) {
            //     capacityListRequest.cancel();
            //     capacityListRequest = null;
            // }

            $seatplanCapacityList.empty();

            var seatplanId = $seatplanList.val();
            capacityListRequest = $.ajax('/zaalindelingen/' + seatplanId + '/capacities.json').then(function (capacities) {
                $.each(capacities, function (name, label) {
                    var option = $('<option />').attr('value', name).text(label);
                    $seatplanCapacityList.append(option);
                })
            })
        });
    }

    // Display dependent items on item lists (i.e. the possibility to select "lunch friday" tickets when you select a "crew friday" ticket)
    if ($('.tc-dependent-item').length >= 1) {
        // !! ATTENTION !!
        // This code is also used in the frontend for the frontend request form

        // Find all dependend items
        var $dependentItems = $('.tc-dependent-item');
        $dependentItems.each(function () {
            // Reference to the dependent item (i.e.: the "lunch friday ticket")
            var $dependentItem = $(this);

            // Find all items that are required for this item (i.e. "crew friday" or "artist friday")
            var requiredItemIds = ($dependentItem.data('required-itemids') || '') + '';
            requiredItemIds = requiredItemIds.split(',');

            // Check if the dependent item should be visible
            function updateVisibility() {
                var showItem = false;
                $.each(requiredItemIds, function (index) {
                    // Find required item input
                    var requiredItemId = requiredItemIds[index]
                    var $requiredItemInput = $('.tc-item[data-item-id=' + requiredItemId + '] input[type=number]');
                    // Chech if it has a value
                    var value = $requiredItemInput.val();
                    if (value > 0) {
                        showItem = true;
                    }
                })
                // Toggle the hidden class
                $dependentItem.toggleClass('hidden', !showItem);
            }

            // Bind a change listener
            $.each(requiredItemIds, function (index) {
                var requiredItemId = requiredItemIds[index]
                var $requiredItemInput = $('.tc-item[data-item-id=' + requiredItemId + '] input[type=number]');
                $requiredItemInput.on('change', function () {
                    updateVisibility()
                });
            });

            // Just update it once
            updateVisibility();
        });
    }

    if ($('.tc-shifts-table').length >= 1) {
        var $table = $('.tc-shifts-table');

        var $collectionHolder = $table.find('tbody');
        $collectionHolder.data('index', $collectionHolder.find('tr').length);

        function bindDelete($row) {
            $row.find('.js-remove').on('click', function (e) {
                e.preventDefault();
                $(this).closest('tr').remove();
            })
        }

        bindDelete($collectionHolder);

        function uploadLocationLabel($shiftsDropdown) {
            var $selectedOption = $shiftsDropdown.find(':selected');
            var locationName;
            if ($selectedOption.length === 0) {
                locationName = '';
            } else {
                var $optGroup = $selectedOption.closest('optgroup');
                locationName = $optGroup.attr('label');
            }
            $shiftsDropdown.closest('td').find('.js-location').text(locationName);
        }

        function bindShifts($row) {
            var $positionDropdown = $row.find('select[id$="_position"]');
            $positionDropdown.on('change', function () {
                loadShifts($row, true)
            });
            loadShifts($row, false);

            var $shiftDropdown = $row.find('select[id$="_shift"]');
            $shiftDropdown.on('change', function () {
                uploadLocationLabel($shiftDropdown);
            })
            uploadLocationLabel($shiftDropdown);
        }

        function loadShifts($row, loadAids) {
            // Get references to inputs
            var $positionDropdown = $row.find('select[id$="_position"]');
            var $shiftDropdown = $row.find('select[id$="_shift"]');
            var $aidsDropdown = $row.find('select[id$="_aids"]');

            // Check which item is currently selected
            var selectedShiftId = $shiftDropdown.val();

            // Clear the dropdown
            $shiftDropdown.empty();

            // Download the available shifts for this position
            var positionId = $positionDropdown.val();
            $.ajax('/crew/werktijden/' + positionId + '/list.json').then(function (data) {
                $.each(data.shifts, function (locationName, times) {

                    // Create a new <optgroup> for each location
                    var $optGroup = $('<optgroup />').attr('label', locationName);
                    $shiftDropdown.append($optGroup);

                    // Add an <option> for each shift to the location optgroup
                    $.each(times, function (index) {
                        var shiftInfo = times[index];
                        var shiftId = shiftInfo.id;
                        var shiftLabel = shiftInfo.name
                        var $option = $('<option />').attr('value', shiftId).text(shiftLabel);
                        if (selectedShiftId == shiftId) {
                            $option.prop('selected', true);
                        }
                        $optGroup.append($option);
                    })
                });

                if (loadAids) {
                    $aidsDropdown.val(data.aids);
                }

                uploadLocationLabel($shiftDropdown);
            })

        }

        $table.find('tbody tr').each(function () {
            var $row = $(this);
            bindShifts($row);
        })

        $table.find('.js-add').on('click', function (e) {
            e.preventDefault();

            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var newRow = prototype.replace(/__name__/g, index);

            // increase the index with one for the next item
            $collectionHolder.data('index', index + 1);

            // Display the form in the page in an li, before the "Add a tag" link li
            var $newRow = $(newRow);
            $collectionHolder.append($newRow);

            bindShifts($newRow);

            bindDelete($newRow);

            // select first organisation
            var $organisationDropdown = $newRow.find('select[id$="_crewOrganisation"]');
            var $options = $organisationDropdown.find('option');
            if ($options.length === 2) {
                $organisationDropdown.find('option:last').attr('selected', 'selected');
            }
        })
    }

    $('.js-datetime').each(function() {
        let $container = $(this);
        let $input = $container.find('input');
        let $clearButton = $container.find('[data-clear]');

        $clearButton.on('click', function(e) {
            e.preventDefault();
            $input.val('');
        })
    })

    if (document.querySelectorAll('[data-tc-freeitem]').length) {
        let checkbox = document.querySelector('[data-tc-freeitem]');
        let salesPriceInput = document.querySelector('#item_salesPrice');
        let vatPercentageInput = document.querySelector('#item_vatPercentage');

        checkbox.addEventListener('input', (e) => {

            salesPriceInput.disabled = checkbox.checked;
            vatPercentageInput.disabled = checkbox.checked;

            if (checkbox.checked) {
                salesPriceInput.value = '0.00';
            } else {
                salesPriceInput.value = '';
            }
        })
    }

    if (document.querySelectorAll('[data-event-day-selection]').length) {
        let eventDayValues = document.querySelectorAll('[data-event-day-selection] input[type=checkbox]:not([value=""])')
        let emptyValues = document.querySelectorAll('[data-event-day-selection] input[type=checkbox][value=""]')

        eventDayValues.forEach(input => {
            input.addEventListener('change', (e) => {
                let allChecked = true, allUnchecked = true, someChecked = false;
                eventDayValues.forEach(i => {
                    allChecked = allChecked && i.checked;
                    allUnchecked = allUnchecked && !i.checked;
                    someChecked = someChecked || i.checked;
                })

                emptyValues.forEach(i => {
                    i.checked = !someChecked;
                })
            });
        })

        emptyValues.forEach(input => {
            input.addEventListener('input', (e) => {
                eventDayValues.forEach(i => i.checked = !input.checked);
            })
        })

    }

    // Entity collection
    $(function() {
        $('.js-entity-collection').find('.js-collection-add').on('click', function(e) {
            e.preventDefault();

            var number = 0;
            let $tbody = $(this).closest('table').find('tbody');
            if ($tbody.find('tr').length > 0) {
                var $lastRow = $tbody.find('tr').last();
                var inputName = $lastRow.find('input, select').first().attr('id').split('_');
                number = parseInt(inputName[inputName.length - 2]) + 1;
            }
            var $prototype = $($(this).closest('.table').attr('data-prototype').replace(new RegExp('__name__', 'g'), number));
            $tbody.append($prototype);

            $prototype.find('select').each(function() {
                if (!$(this).hasClass('no-selectize')) {
                    //Selectize.init(this);
                }
            })

            $('.js-entity-collection').trigger('row-add', [$prototype])
        })
        $('.js-entity-collection').on('click', '.js-collection-delete', function() {
            $(this).closest('tr').remove();
            $('.js-entity-collection').trigger('row-delete')
        })
    })

    if ($('.tc-crew-facilities').length > 0) {
        function bindFacilityRow($row) {
            let $facilityDropdown = $row.find('.tc-facility-select');
            let $choiceInput = $row.find('.tc-facility-choice');

            $facilityDropdown.on('change', () => {
                let value = $facilityDropdown.find(':selected').data('facilityType');
                let isChoice = (value === 'choice')
                $choiceInput.prop('disabled', !isChoice);
                if (!isChoice) {
                    $choiceInput.val('');
                }
            }).trigger('change');
        }

        let $rows = $('.tc-crew-facilities').find('tbody tr');
        $rows.each(() => {
            let $row = $(this);
            bindFacilityRow($row);
        })

        $('.tc-crew-facilities').on('row-add', (evt, $row) => {
            bindFacilityRow($row);
        })

    }


    document.querySelectorAll('.js-copy-to-clipboard').forEach(element => {
        element.addEventListener('click', (evt) => {
            let data = element.dataset.clipboardData
            navigator.clipboard.writeText(data).then(() => {
                console.log('written', { data, dataset: element.dataset})
                const icon = element.querySelector('i');
                if (icon && icon.classList.contains('fa-clipboard')) {
                    icon.classList.remove('fa-clipboard');
                    icon.classList.add('fa-check');

                    if (element.resetTimeout) {
                        window.clearTimeout(element.resetTimeout);
                    }
                    console.log(' reset queued');
                    element.resetTimeout = window.setTimeout(() => {
                        console.log('reset')
                        icon.classList.remove('fa-check');
                        icon.classList.add('fa-clipboard')
                    }, 3000)
                }
            })

            evt.preventDefault()
        })
    })

    /**
     * Dit veld wordt nu nog alleen binnen de webshop gebruikt.
     * Dit script past automatisch de url's in de clipboard en qr-code button bij
     */
    document.querySelectorAll('[data-slug-field]').forEach(inputGroup => {
        let input = inputGroup.querySelector('input[type=text]');

        let clipboardButton = inputGroup.querySelector('.js-copy-to-clipboard');
        let qrcodeButton = inputGroup.querySelector('.js-copy-qrcode');

        input.addEventListener('change', (evt) => {
            const url = inputGroup.dataset.slugPrefix + evt.target.value;
            if (clipboardButton) {
                clipboardButton.dataset.clipboardData = url;
            }
            if (qrcodeButton) {
                qrcodeButton.dataset.qrcodeData = url;
                qrcodeButton.dataset.qrcodeFilename = `ticketcrew-${evt.target.value}-qr.png`;
            }
        })

    })

    // Deze knop genereert automatisch een QR-code en biedt deze ter download aan.
    document.querySelectorAll('[data-qrcode-button]').forEach(button => {
        button.addEventListener('click', (evt) => {

            // Data in de QR-code
            const data = button.dataset.qrcodeData;

            // Basis generator url uit de configuratie aanvullen met de code.
            let generatorUrl = button.dataset.qrcodeGeneratorUrl;
            generatorUrl += ((generatorUrl.indexOf('?') !== -1) ? '&' : '?')+'code='+encodeURIComponent(data);

            // Button voor nu disablen; en een spinner tonen tijdens de download
            const icon = button.querySelector('i');
            button.disabled = true;
            if (icon) {
                icon.className = 'fa-fw fal fa-spinner-third fa-spin';
            }

            // Start download
            fetch(generatorUrl)
                .then(response => response.blob())
                .then(file => {
                    // Creeer link
                    let tempLink = URL.createObjectURL(file);
                    let anchorTag = document.createElement('a')
                    anchorTag.href = tempLink
                    anchorTag.download = button.dataset.qrcodeFilename; // download filename komt ook uit de attributen
                    // Trigger download
                    anchorTag.click();

                    // Download icoontje tonen
                    if (icon) {
                        icon.className = 'fa-fw fal fa-download';
                    }

                    // Na 3 seconden alles reset
                    button.resetTimeout = window.setTimeout(() => {
                        button.disabled = false;
                        anchorTag.remove();
                        URL.revokeObjectURL(tempLink);

                        if (icon) {
                            icon.className = 'fa-fw fal fa-qrcode';
                        }
                    }, 3000)
                })

        })
    })

})
