require('../../scss/backend/_colorpicker.scss');

const colorpickers = document.querySelectorAll('.fw-color-picker');
colorpickers.forEach(colorpicker => {
    const input = colorpicker.querySelector('input[type=text]')
    const color = colorpicker.querySelector('input[type=color]');
    const icon = colorpicker.querySelector('.colorpicker-input-addon i');
    const isOverridable = colorpicker.classList.contains('fw-overridable-color-picker');
    const clearButtonAppend = colorpicker.querySelector('.colorpicker-clear');
    const clearButton = colorpicker.querySelector('.colorpicker-clear button');
    const parentColor = input.getAttribute('placeholder')

    let _delay = null;
    let _updating = false
    function setColor(c)
    {
        if (_delay) {
            window.clearTimeout(_delay);
        }

        window.setTimeout(() => {
            if (_updating) return;
            _updating = true;

            input.value = c;
            color.value = c;

            if (!c && isOverridable) {
                icon.style.backgroundColor = parentColor;
            } else {
                icon.style.backgroundColor = c;
            }

            if (clearButtonAppend) {
                clearButtonAppend.classList.toggle('d-none', !c);
            }

            _updating = false;
        }, 20);
    }

    icon.addEventListener('click', () => {
        color.click();
    })

    color.addEventListener('input', () => {
        setColor(color.value);
    })


    input.addEventListener('input', () => {
        setColor(input.value);
    })

    if (clearButton) {
        clearButton.addEventListener('click', () => {
            setColor(null);
        })
    }
})
