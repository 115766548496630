var c3 = require('c3');

$(function() {
    if ($('.tc-chart-orders').length > 0) {
        var $chartContainer = $('.tc-chart-orders');
        var url = $chartContainer.data('url');

        c3.generate({
            bindto: '.tc-chart-orders',
            data: {
                x: 'x',
                url: url,
                mimeType: 'json',
                columns: []
            },
            padding: {
                right: 30
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d'
                    }
                }
            },
            point: {
                r: 2
            },
            grid: {
                y: {
                    show: true
                }
            }
        });
    }

  if ($('.tc-chart').length > 0) {
    var $chartContainer = $('.tc-chart');
    var chartData = $chartContainer.data('chart');

    var chart = c3.generate({
      bindto: '.tc-chart',
      data: {
        x: 'x',
        columns: chartData
      },
      size: {
        height: 800
      },
      padding: {
        right: 30
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        }
      },
      point: {
        r: 2
      },
      grid: {
        y: {
          show: true
        }
      }
    });
  }
})