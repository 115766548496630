$(function() {
    if ($('.js-validate-promotion').length > 0) {
        function checkPromotionCode() {
            var code = $('.js-promotion-code-input').val().trim();
            if (code.length > 0) {
                var url = $('.couponForm').data('check-url');
                var $input = $('<input type="hidden" name="code" />').val(code);
                var $form = $('<form method="post"></form>').attr('action', url);
                $input.appendTo($form);
                $form.appendTo('body');
                $form.submit();
            }
        }
        $('.js-promotion-code-input').on('keydown', function(e) {
            if (e.which === 13) {
                e.preventDefault();
                checkPromotionCode();
            }
        });
        $('.js-validate-promotion').on('click', function(e) {
            e.preventDefault();
            checkPromotionCode();
        });
    }
})
