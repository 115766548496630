module.exports = {
    messages: {
        ui: {
            "blockTunes": {
                "toggler": {
                    "Click to tune": "Klik om aan te passen",
                        "or drag to move": "of versleep om te verplaatsen"
                },
            },
            "inlineToolbar": {
                "converter": {
                    "Convert to": "Converteren naar"
                }
            },
            "toolbar": {
                "toolbox": {
                    "Add": "Toevoegen"
                }
            }
        },

        /**
         * Section for translation Tool Names: both block and inline tools
         */
        toolNames: {
            "Text": "Tekst",
                "Heading": "Kop",
                "List": "Lijst",
                "Warning": "Waarschuwing",
                "Quote": "Citaat",
                "Code": "Code",
                "Delimiter": "Scheiding",
                "Raw HTML": "Ruwe HTML",
                "Table": "Tabel",
                "Link": "Link",
                "Marker": "Markeren",
                "Bold": "Vet",
                "Italic": "Cursief",
                "InlineCode": "Code",
                "Ordered List": "Genummerde lijst",
                "Unordered List": "Opsommingslijst",
                "Checklist": "Checklist",
        },

        /**
         * Section for passing translations to the external tools classes
         */
        tools: {
            "List": {
                'Unordered': 'Opsomming',
                    'Ordered': 'Genummerd',
                    'Checklist': 'Checklist',
            }
        },

        /**
         * Section allows to translate Block Tunes
         */
        blockTunes: {
            /**
             * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
             * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
             *
             * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
             */
            "delete": {
                "Delete": "Verwijderen"
            },
            "moveUp": {
                "Move up": "Verplaats omhoog"
            },
            "moveDown": {
                "Move down": "Verplaats omlaag"
            }
        },
    }
}
