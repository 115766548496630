import List from '@editorjs/list';
class CustomList extends List {
    renderSettings() {
        return super.renderSettings().filter((item) => {
            console.log(item);

            // Here you can filter needed items
            // In my case I need to only support unordered and ordered lists without any new options like 'checklist' or 'start from'
            // But if you want to only disable Checklist you can filter like (item) => item.label !== 'Checklist'
            return ['Unordered', 'Ordered'].includes((item).label) //  as { label: string } if TS
        });
    }
}

export default CustomList
