import {FwAtNotify} from 'admin-theme-bundle/assets/js/modules/notify'

document.addEventListener('DOMContentLoaded', () => {
    // Handle flashes
    const flashes = JSON.parse(document.body.getAttribute('data-flashes'));
    for (let [type, messages] of Object.entries(flashes)) {
        messages.forEach((message) => {
            FwAtNotify.send(message, type)
        });
    }

    document.body.removeAttribute('data-flashes');
})
