/**
 * This plugins makes the header of a table sticky, when scrolling down
 */
export class StickyTableHeader
{
    settings
    scrollContainer
    stickyBackdrop

    /**
     * Create new instance
     * @param settings
     */
    constructor(settings = {}) {
        this.settings = {
            // Default values
            scrollContainerSelector: '.wrapper',

            // Merge with provided settings
            ...settings
        };

        this.tables = [];
    }

    /**
     * Initializes the sticky table header, on the provided selector.
     * (This function is automatically called at the bottom of this file with selector `[fwat-sticky-table-header]`)
     * @param selector
     */
    initializeSelector(selector) {
        // Find all tables matching the selector
        this.tables = document.querySelectorAll(selector);

        // Initialize each of the tables
        this.tables.forEach((tableElement) => {
            this.initialize(tableElement)
        });

        // Find the main scroll container (the element that is scrolled)
        this.scrollContainer = document.querySelector(this.settings.scrollContainerSelector);

        // Listen to resize and scroll events on the scroll container
        this.scrollContainer.addEventListener('resize', () => { this.update() })
        this.scrollContainer.addEventListener('scroll', () => { this.update() })

        // Create a backdrop class, this is the div that is shown behind the sticky headers
        this.stickyBackdrop = document.createElement('div');
        this.stickyBackdrop.classList.add('fwat-sticky-table-backdrop');

        // This is the container div within the sticky backdrop. We re-use the container, so it uses the same margins
        // as the content
        this.stickyBackdropContainer = document.createElement('div');
        this.stickyBackdropContainer.classList.add('container');
        this.stickyBackdrop.appendChild(this.stickyBackdropContainer);
        this.scrollContainer.appendChild(this.stickyBackdrop);
    }

    initialize(tableElement) {
        // Remove the `is-sticky` class for any remaining table elements
        tableElement.classList.remove('is-sticky');
    }

    /**
     * This function is called on each resize or scroll
     */
    update() {

        // Get the current scroll offset
        const scrollY = this.scrollContainer.scrollTop;

        // Track if there is a sticky table in view
        let containsStickyTable = false;

        // Create a function pointer for the update-scroll; that is provided to the container
        let updateScrollFn = this.updateScroll.bind(this);

        // Loop through each of the tables on the page
        this.tables.forEach((tableElement) => {
            // Find the <thead> of the table
            const tableHead = tableElement.querySelector('thead');
            // Find the vertical offset of the head
            const tableHeaderOffset = tableHead.getBoundingClientRect().top;

            // This table should nbe sticky if we have scrolled passed the header
            const isSticky = (scrollY > tableHeaderOffset)
            tableElement.classList.toggle('is-sticky', isSticky);

            if (isSticky) {

                // Reference for scroll function
                this.stickyTable = tableElement;

                // Remove all elements from the backdrop
                while (this.stickyBackdropContainer.firstChild) {
                    this.stickyBackdropContainer.removeChild(this.stickyBackdropContainer.lastChild);
                }

                // Update the height of the backdrop with the (outer) height of the table head
                this.stickyBackdrop.style.height = tableHead.clientHeight + 'px';

                // Copy the header and add in backdrop
                this.headerClone = tableHead.cloneNode(true);

                // Copy header widths
                for(var i = 0; i < tableHead.children.item(0).children.length; i++) {
                    let width = tableHead.children.item(0).children.item(i).clientWidth;
                    this.headerClone.children.item(0).children.item(i).style.minWidth = width + 'px';
                }

                // Create a new table within the header
                let table = document.createElement('table');
                table.classList.add('table');

                table.appendChild(this.headerClone);

                this.stickyBackdropContainer.append(table);

                // Listen to (horizontal) scroll events in the table, so we update the header as well
                let responsiveContainer = tableElement.parentNode;
                responsiveContainer.removeEventListener('scroll', updateScrollFn);
                responsiveContainer.addEventListener('scroll', updateScrollFn);
                // Update the header on first load as well
                this.updateScroll();
            } else {
                // Stop listening to scroll events
                tableElement.parentNode.removeEventListener('scroll', updateScrollFn);
            }

            // Update the main flag if there is a sticky table
            containsStickyTable = containsStickyTable || isSticky
        })

        // Add the `is-sticky` to the backdrop
        this.stickyBackdrop.classList.toggle('is-sticky', containsStickyTable);

    }

    /**
     * Updates the horizontal scroll offset of the table headers in the header,
     */
    updateScroll()
    {
        this.stickyBackdropContainer.scrollLeft = this.stickyTable.parentNode.scrollLeft;
    }
}

// Automatically initialize
if (document.querySelectorAll('[fwat-sticky-table-header]').length > 0) {
    const stickyTableHeader = new StickyTableHeader();
    stickyTableHeader.initializeSelector('[fwat-sticky-table-header]');
}
