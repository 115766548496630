/*
 * This library build a modal viewer (dialog) for viewing PDF document.
 */
$(function() {
    // $('.js-pdf-link').each(function() {
    //
    //     var viewportWidth = document.documentElement.clientWidth;
    //     if (viewportWidth < 700) {
    //         // don't open in modal on small screens
    //         return;
    //     }
    //
    //     var $link = $(this);
    //     $link.on('click', function(e) {
    //         var url = $link.attr('href');
    //         var downloadUrl = url;
    //         if (url.indexOf('download=') === -1) {
    //             if (url.indexOf('?') === -1) {
    //                 url += '?download=false';
    //             } else {
    //                 url += '&download=false';
    //             }
    //         }
    //
    //         e.preventDefault();
    //
    //         var title = $link.attr('title');
    //
    //         var $dialog = $(
    //             '<div class="ks-document-viewer modal fade fw-pdf-viewer" tabindex="-1" role="dialog">'+
    //                 '<div class="modal-dialog" role="document">'+
    //                     '<div class="modal-content">'+
    //                         '<div class="modal-header">'+
    //                             '<h5 class="modal-title"><span class="ks-modal-title-icon fa-file-pdf"></span>'+title+'</h5>'+
    //                             '<div class="ks-controls">'+
    //                                 '<a href="'+downloadUrl+'" class="btn btn-primary fw-btn-icon"><span class="fal fa-download icon"></span><span class="text">Download</span></a>'+
    //                             '</div>'+
    //                             '<div class="ks-extra-control"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" class="la la-close"></span> </button></div>'+
    //                         '</div>'+
    //                         '<div class="modal-body">'+
    //                             //'<div class="embed-responsive embed-responsive-a4">'+
    //                             '<iframe src="'+url+'"  />'+ //class="embed-responsive-item"
    //                             //'</div>'+
    //                         '</div>'+
    //                     '</div><!-- /.modal-content -->'+
    //                 '</div><!-- /.modal-dialog -->'+
    //             '</div><!-- /.modal -->');
    //         $dialog.appendTo('body');
    //         $dialog.on('hidden.bs.modal', function(){
    //             $('.modal-backdrop').fadeOut(300, function() { $(this).remove() });
    //         });
    //         $dialog.modal({ show: true }); // TODO: Check/fix JS error
    //
    //     })
    // })
});
