//import { FwAtCustomSelect } from 'admin-theme-bundle/assets/js/modules/custom-select'
const {FwAtCustomSelect} = require('admin-theme-bundle/assets/js/modules/custom-select')

$(function() {

    if ($('#user-form').length > 0) {
        var $form = $('#user-form');

        var $roleSelect = $('input[name="user\\[role\\]"]');
        $roleSelect.on('change', function () {

            var value = $('input[name="user\\[role\\]"]:checked').val();
            $('.form-group.organisation').toggle(value === 'ROLE_ORGANISATION');
            $('.form-group.point-of-sale').toggle(value === 'ROLE_POINT_OF_SALE');

        }).trigger('change');

        // Collection support:
        // http://symfony.com/doc/current/form/form_collections.html#form-collections-new-prototype
    }

    $('.fw-table-collection').each(function () {
        var $table = $(this);

        var $collectionHolder = $table.find('tbody');
        $collectionHolder.data('index', $collectionHolder.find('tr').length);

        function bindDelete($row) {
            $row.find('.js-remove').on('click', function (e) {
                e.preventDefault();
                $(this).closest('tr').remove();
            })
        }

        bindDelete($collectionHolder);

        if ($table.hasClass('tc-organisation-role-collection')) {
            function bindOrganisationEvents($rows) {
                $rows.each((index, element) => {
                    let $row = $(element);
                    let $organisationDropdown = $row.find('[id$=organisation]');
                    let $roleDropdown = $row.find('[id$=role]');
                    let $eventDropdown = $row.find('[id$=event]');

                    let roles = $roleDropdown.data('eventRoles').split(',');

                    function updateEvents() {
                        let role = $roleDropdown.val();
                        let needsEvent = roles.indexOf(role) !== -1;

                        $eventDropdown.toggle(needsEvent);

                        let organisationId = $organisationDropdown.val();
                        if (needsEvent && $eventDropdown.data('organisationId') !== organisationId) {
                            let currentValue = $eventDropdown.find(':selected').attr('value');
                            $.ajax('/users/list-events/' + organisationId)
                                .then(response => {
                                    $eventDropdown.data('organisationId');

                                    $eventDropdown.empty();

                                    response.forEach(option => {
                                        var $option = $('<option>').attr('value', option.id).text(option.label).prop('selected', option.id == currentValue);
                                        $eventDropdown.append($option);
                                    })
                                })
                        }
                    }

                    $organisationDropdown.on('change', updateEvents);
                    $roleDropdown.on('change', updateEvents);
                    updateEvents();
                })
            }

            bindOrganisationEvents($table.find('tbody tr'))
        }

        $table.find('.js-add').on('click', function (e) {
            e.preventDefault();

            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var newRow = prototype.replace(/__name__/g, index);

            // increase the index with one for the next item
            $collectionHolder.data('index', index + 1);

            // Display the form in the page in an li, before the "Add a tag" link li
            var $newRow = $(newRow);

            // Automatisch display order alvast vullen
            if ($table.hasClass('tc-webshop-items')) {
                var $displayOrderInputs = $collectionHolder.find('input[name*=displayOrder]')
                var largestDisplayOrder = 0;
                $displayOrderInputs.each(function (index, input) {
                    largestDisplayOrder = Math.max(largestDisplayOrder, parseInt($(input).val()));
                });
                $newRow.find('input[name*=displayOrder]').val(largestDisplayOrder + 10);
            }

            if ($table.hasClass('tc-organisation-role-collection')) {
                bindOrganisationEvents($newRow)
            }

            const customSelects = $newRow.find('[data-fw-at-custom-select]');
            if (customSelects.length >= 1) {
                const customSelect = new FwAtCustomSelect();
                customSelects.each((index, el) => {
                    customSelect.initialize(el)
                })
            }

            $collectionHolder.append($newRow);

            bindDelete($newRow);
        })
    })

    $('.fw-list-collection').each(function () {

        let $collectionHolder = $(this);
        let $list = $collectionHolder.prop('tagName') == 'UL' ? $collectionHolder : $collectionHolder.find('ul');

        const isAddButtonInList = $list.find('.js-add').length > 0; // Beetje vies; maar deze collection wordt ook door de form builder gebruikt; en daar staat de add button in de <ul>
        $collectionHolder.data('index', $collectionHolder.find('li').length + (isAddButtonInList ? -1 : 0));

        function bindDelete($row) {
            $row.find('.js-remove').on('click', function (e) {
                e.preventDefault();
                $(this).closest('li').remove();
            })
        }

        bindDelete($collectionHolder);

        $collectionHolder.find('.js-add').on('click', function (e) {
            e.preventDefault();

            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var newRow = prototype.replace(/__name__/g, index);

            // increase the index with one for the next item
            $collectionHolder.data('index', index + 1);

            // Display the form in the page in an li, before the "Add a tag" link li
            var $newRow = $(newRow);
            if (isAddButtonInList) {
                $newRow.insertBefore($(this).closest('li'));
            } else {
                $list.append($newRow);
            }

            bindDelete($newRow);
        })
    })
})
