
let containers = document.querySelectorAll('.fw-auto-update-table');

if (containers.length === 1) {
    // Create some useful references
    const container = containers[0];
    const form = container.querySelector('form');
    const inputs = form.querySelectorAll('input');

    // Load configuration from the main container element
    const contentSelector = container.dataset.fwContentSelector || '.card-block';
    const updateTitle = container.dataset.fwTitleSelector || '.card-title';
    const itemSelector = container.dataset.fwItemSelector || 'tbody tr';

    // Create abort controller and update timer, so we send as little requests to the server as needed
    let abortController = null;
    let updateTimer;

    // Keep track if the user has pressed enter; so we can automatically go the first result
    let continueToFirst = false;
    let activeQuery = null;

    /**
     * Called when a filter option has been changed
     * @param isSubmit if the user has pressed enter (this will trigger an immediate refresh)
     */
    function onFilterChange(isSubmit) {

        // Debounce calls to the loadNewData
        window.clearTimeout(updateTimer);

        // Abort pending requests
        if (abortController) {
            abortController.abort();
        }

        // Store if the call should go to the first result
        continueToFirst = !!isSubmit;

        // Start small timer to update data
        updateTimer = window.setTimeout(() => {
            loadNewData();
        }, continueToFirst ? 1 : 200) // if it was an enter, submit direct
    }

    function loadNewData() {
        // Convert the form data to an URL QueryString
        const data = new URLSearchParams(new FormData(form)).toString()

        // Create new abort controller
        abortController = new AbortController();
        const {signal} = abortController;

        // Fetch results
        window.fetch('?'+data, { signal })
            .then(response => response.text()) // convert to HTML
            .then(body => {
                // Update url
                history.replaceState(data, document.title, '?'+data);

                // Create <template> from the body
                let template = document.createElement('template');
                template.innerHTML = body;

                // find and replace content
                let newContent = template.content.querySelector(contentSelector)
                let currentContent = container.querySelector(contentSelector);
                currentContent.replaceWith(newContent);

                // find and replace title
                if (updateTitle) {
                    let newTitle = template.content.querySelector(updateTitle)
                    let currentTitle = container.querySelector(updateTitle);
                    currentTitle.replaceWith(newTitle);
                }

                this.activeQuery = data;

                if (continueToFirst) {
                    selectFirstItem();
                }
            })
            .catch(e => {
                if(e.name === "AbortError") {
                    // We know it's been canceled!
                } else {
                    throw e;
                }
            })

    }

    function onSubmit(e) {
        e.preventDefault();

        // Check if we're working on a new query.
        // If the browser is still loading data; abort the timer or request, and start right away
        // If the enter was pressed after the data is loaded; we can just go to the first result
        const data = new URLSearchParams(new FormData(form)).toString();
        if (activeQuery !== data) {
            onFilterChange(true);
        } else {
            selectFirstItem();
        }

        if (form.hasAttribute('data-fw-at-mobile-search-form')) {
            let mobileSearchFormTrigger = container.querySelector('.mobile-search-form-trigger');
            mobileSearchFormTrigger.parentNode.querySelector('[data-fw-at-mobile-search-form]').classList.toggle('active', false);
            document.body.classList.toggle('open-mobile-search-form', false);
        }
    }

    function selectFirstItem() {
        // Find the first item
        let content = container.querySelector(contentSelector);
        let items = content.querySelectorAll(itemSelector);
        if (items.length === 1) {
            // Click the first link in the first item
            let link = items[0].querySelector('a');
            link.click();
        } else if (items.length === 0) {
            // No item found matching the itemSelector.
            // Use the `data-fw-item-selector` attribute to set the selector for items
            console.log('No item found in content');
        }
    }

    inputs.forEach(input => {
        input.addEventListener('input', () => { onFilterChange(false) });
    })

    form.addEventListener('submit', onSubmit);
}

