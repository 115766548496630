import Vue from 'vue/dist/vue.js';
import draggable from 'vuedraggable'

const webshopItemsElement = document.getElementById('tc-webshop-items-app');
new Vue({
    el: webshopItemsElement,
    components: {
        draggable,
    },
    mounted() {
        this.config = JSON.parse(this.$el.dataset.config);
        this.baseFieldName = this.$el.dataset.baseFieldName;

        this.webshopItems = this.config.items;
        delete this.config.items

        this.events = this.config.events;
        delete this.config.events;

        this.selectedEventId = this.config.selected_event_id;
        delete this.config.selected_event_id;
    },
    data: {
        baseFieldName: null,
        config: {},
        webshopItems: [],
        eventItems: [],
        events: [],
        selectedEventId: [],
    },
    watch: {
        selectedEventId() {
            this.loadEventItems();
        }
    },
    methods: {
        loadEventItems() {
            if (!this.selectedEventId) {
                return;
            }

            fetch(this.config.items_url+'?event_id='+this.selectedEventId)
                .then(response => response.json())
                .then(data => { this.eventItems = data; })
        },
        addEventItem(eventItem) {
            this.webshopItems = [...this.webshopItems, this.convertToWebshopItem(eventItem) ];

            this.updateDisplayOrders()
        },
        removeWebshopItem(webshopItem) {
            this.webshopItems = this.webshopItems.filter(wi => wi !== webshopItem);
        },
        containsEventItem(eventItem) {
            return this.webshopItems.find(wi => wi.item.id === eventItem.id);
        },
        convertToWebshopItem(eventItem) {
            return {
                title: '',
                description: '',
                displayOrder: 9999,
                item: eventItem
            }
        },
        onWebshopItemsChanged(e) {
            this.updateDisplayOrders()
        },
        updateDisplayOrders() {
            this.webshopItems.forEach((webshopItem, index) => {
                webshopItem.displayOrder = (++index);
            })
        },
        getFieldName(index, name) {
            return `${this.baseFieldName}[${index}][${name}]`
        }
    }
});

