$(function() {

    $('table.tc-table-selectable').each(function() {
        let $table = $(this);

        let $selectAll = $table.find('[data-bs-toggle=table-select-all]');
        let $selectRow = $table.find('[data-bs-toggle=table-select-row]');
        let $batchActions = $('[data-bs-toggle=table-batch-action]');

        $selectAll.on('input', function() {
            let $input = $(this)
            let checked = $input.is(':checked')

            $selectRow.prop('checked', checked)
            updateStatus();
        })

        $selectRow.on('input', function(e) {
            e.preventDefault();

            let $input = $(this);
            let checked = $input.is(':checked')

            updateStatus();
        })

        function updateStatus() {
            let allChecked = true;
            let allNotChecked = true;
            let hasSelection = false;

            $selectRow.each(function() {
                let $input = $(this);
                let checked = $input.is(':checked');

                if (checked) {
                    allNotChecked = false;
                    hasSelection = true;
                } else {
                    allChecked = false;
                }
            })

            if (allChecked) {
                $selectAll.prop('checked', true)
                $selectAll.prop('indeterminate', false);
            } else if (allNotChecked) {
                $selectAll.prop('checked', false);
                $selectAll.prop('indeterminate', false);
            } else {
                $selectAll.prop('indeterminate', true);
            }

            $batchActions.prop('disabled', !hasSelection);

        }
    })

});
