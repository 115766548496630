import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import CustomList from './CustomList';
import QrCodeImage from './QrCodeImage';

// https://editorjs.io/i18n/
const editorJsI18nConfig = require('./i18n')

window.addEventListener('load', () => {
    document.querySelectorAll('[data-blockeditor]').forEach(editorElement => {
        const isDebug = true;

        const inputId = editorElement.dataset.blockeditorInput;
        const input = document.getElementById(inputId);

        const editor = new EditorJS({
            holder: editorElement.id,
            placeholder: editorElement.dataset.placeholder ?? null,
            readonly: editorElement.dataset.readonly ?? false,

            onReady: () => {
                console.log('Ticketcrew Block Editor ready', {editorElement})
                editorElement.tcBlockEditor = editor
                editorElement.tcBlockEditorInput = input
            },

            tools: {
                header: Header,
                list: {
                    class: CustomList,
                    toolbox: [
                        {
                            data: { style: 'ordered' },
                        },
                        {
                            data: { style: 'unordered' },
                        },
                    ],
                },
                qrcode: QrCodeImage
            },

            data: input.value ? JSON.parse(input.value) : null,

            onChange: (api, event) => {
                editor.save().then(outputData => {
                    console.log('Editor changed', outputData);
                    input.value = JSON.stringify(outputData)
                })
            },

            i18n: editorJsI18nConfig
        });
    })

    document.querySelectorAll('[data-blockeditor-variable]').forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault()
            const editor = document.getElementById(e.target.dataset.blockeditorId).tcBlockEditor;
            console.log(editor);
        })
    })
})

document.addEventListener("DOMContentLoaded", function(){
// make it as accordion for smaller screens
    if (window.innerWidth < 992) {

        // close all inner dropdowns when parent is closed
        document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
            everydropdown.addEventListener('hidden.bs.dropdown', function () {
                // after dropdown is hidden, then find all submenus
                this.querySelectorAll('.submenu').forEach(function(everysubmenu){
                    // hide every submenu as well
                    everysubmenu.style.display = 'none';
                });
            })
        });

        document.querySelectorAll('.dropdown-menu a').forEach(function(element){
            element.addEventListener('click', function (e) {
                let nextEl = this.nextElementSibling;
                if(nextEl && nextEl.classList.contains('submenu')) {
                    // prevent opening link if link needs to open dropdown
                    e.preventDefault();
                    if(nextEl.style.display == 'block'){
                        nextEl.style.display = 'none';
                    } else {
                        nextEl.style.display = 'block';
                    }

                }
            });
        })
    }
// end if innerWidth
});
// DOMContentLoaded  end
